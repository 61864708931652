import { DATETIME } from '@/constants/dateTime';
import { getWeather } from '@/hooks/utils/useGetWeather';
import { IWeather } from '@/interfaces/weather';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Image from 'next/image';

const TimeWeather = () => {
  const [dataWeather, setDataWeather] = useState<IWeather>();

  useEffect(() => {
    const handleSuccessCoor: PositionCallback = async position => {
      const data = await getWeather({
        lat: position?.coords?.latitude,
        lon: position?.coords?.longitude,
      });

      setDataWeather(data);
    };

    const handleErrorCoor = async () => {
      const data = await getWeather();
      setDataWeather(data);
    };

    navigator.geolocation.getCurrentPosition(
      handleSuccessCoor,
      handleErrorCoor,
    );
  }, []);

  return (
    <div className="flex gap-[10px] items-center">
      <div>
        <p className="text-[17px] font-bold text-DarkBlue">
          {dayjs().format(DATETIME.HASDAYINWEEK)}
        </p>
        {dataWeather ? (
          <p className="text-[17px] text-DarkBlue">
            <span className="font-bold">{dataWeather?.location}, </span>
            {dataWeather?.minTemp} °C - {dataWeather?.maxTemp} °C
          </p>
        ) : (
          <div className="animate-pulse h-[26px] w-[230px] bg-slate-200"></div>
        )}
      </div>
      <div>
        {dataWeather ? (
          <Image
            src={`https:${dataWeather?.icon}`}
            width={50}
            height={50}
            alt={dataWeather?.textCondition ?? ''}
          />
        ) : (
          <div className="animate-pulse h-10 w-10 bg-slate-200"></div>
        )}
      </div>
    </div>
  );
};
export default TimeWeather;
