import { defaultLocation } from '@/constants/location';
import axios from 'axios';

interface GetWeatherParams {
  lat?: string | number;
  lon?: string | number;
}

export const getWeather = async (payload?: GetWeatherParams) => {
  const url = `https://api.weatherapi.com/v1/forecast.json`;
  const { lat = defaultLocation.latitude, lon = defaultLocation.longitude } =
    payload || {};
  try {
    const data = await axios.get(url, {
      params: {
        key: process.env.NEXT_PUBLIC_WEATHERAPI,
        q: `${lat},${lon}`,
        days: 1,
        aqi: 'no',
        alerts: 'no',
        lang: 'vi',
      },
    });

    const weather = {
      location: data?.data?.location?.name,
      minTemp: data?.data?.forecast?.forecastday?.[0]?.day?.mintemp_c,
      maxTemp: data?.data?.forecast?.forecastday?.[0]?.day?.maxtemp_c,
      icon: data?.data?.forecast?.forecastday?.[0]?.day?.condition?.icon,
      textCondition:
        data?.data?.forecast?.forecastday?.[0]?.day?.condition?.text,
    };
    return weather;
  } catch (error) {
    return undefined;
  }
};
